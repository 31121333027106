import logo from './logo.png';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header>
        <div className='logo-container'>
          <img src={logo} alt='logo' className='app-logo'></img>
        </div>
        <nav className='navbar'>
          <a href='tel:9036338636' className='navbar__items'><svg height="15px" version="1.1" viewBox="0 0 18 18" width="18px"><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" id="Icons-Communication" transform="translate(-85.000000, -126.000000)"><g id="phone" transform="translate(85.000000, 126.000000)"><path d="M3.6,7.8 C5,10.6 7.4,12.9 10.2,14.4 L12.4,12.2 C12.7,11.9 13.1,11.8 13.4,12 C14.5,12.4 15.7,12.6 17,12.6 C17.6,12.6 18,13 18,13.6 L18,17 C18,17.6 17.6,18 17,18 C7.6,18 0,10.4 0,1 C0,0.4 0.4,0 1,0 L4.5,0 C5.1,0 5.5,0.4 5.5,1 C5.5,2.2 5.7,3.4 6.1,4.6 C6.2,4.9 6.1,5.3 5.9,5.6 L3.6,7.8 L3.6,7.8 Z" id="Shape"/></g></g></g></svg><span>Contact Us</span></a>
          <a href='mailto:trentelevators@gmail.com' className='navbar__items'><svg id="chat" viewBox="0 0 115.99 122.88"><title>dialog</title><path d="M17.4,0H76.75a17.45,17.45,0,0,1,17.4,17.4V55.68a17.45,17.45,0,0,1-17.4,17.4H47.16l-26,22.36a3.12,3.12,0,0,1-4.39-.35A3.06,3.06,0,0,1,16,92.86l1.36-19.78A17.45,17.45,0,0,1,0,55.68V17.4A17.45,17.45,0,0,1,17.4,0ZM66.62,31.14a6,6,0,1,1-6,6,6,6,0,0,1,6-6Zm-19.55,0a6,6,0,1,1-6,6,6,6,0,0,1,6-6Zm-19.54,0a6,6,0,1,1-6,6,6,6,0,0,1,6-6Zm74.81-3A16.87,16.87,0,0,1,116,44.67V83A16.87,16.87,0,0,1,99.18,99.77h-.61l1.41,20.4h0a2.57,2.57,0,0,1-.6,1.82,2.54,2.54,0,0,1-3.58.28L69.63,99.11H35.17l17-17.26h36a14.32,14.32,0,0,0,14.27-14.27V29.29c0-.38,0-.76,0-1.13ZM76.75,6.25H17.4A11.18,11.18,0,0,0,6.25,17.4V55.68A11.18,11.18,0,0,0,17.4,66.83h3.53a3.13,3.13,0,0,1,2.91,3.32L22.75,85.84l21.06-18.1a3.14,3.14,0,0,1,2.2-.91H76.75A11.2,11.2,0,0,0,87.9,55.68V17.4A11.2,11.2,0,0,0,76.75,6.25Z"/></svg><span>Get In Touch</span></a>
        </nav>
      </header>
      <section>
        <h1>Coming Soon <span className='typing'><span></span><span></span><span></span></span></h1>
      </section>
    </div>
  );
}

export default App;
